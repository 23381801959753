import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IntroCards from 'components/1080p_Series_CGI_List/Multimedia_Menu/multiCGI';
import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1080p Serie CGI List :: Multimedia Menu' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1080p Serie CGI List :: Multimedia Menu' image='/images/Search/AU_SearchThumb_CGICommands_1080p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1080p_Serie_CGI_Befehle/Multimedia_Menu/' locationFR='/fr/1080p_Series_CGI_List/Multimedia_Menu/' crumbLabel="Multimedia" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <IntroCards mdxType="IntroCards" />
    {/* * <Link to="/1080p_Serie_CGI_Befehle/Multimedia_Menu/Audio/">Audio</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Multimedia_Menu/Image/">Image</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Multimedia_Menu/Overlays/">Overlays</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Multimedia_Menu/Privacy_Mask/">Privacy Mask</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Multimedia_Menu/Video/">Video</Link> */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      